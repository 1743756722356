import styles from './MobileClubSearchInput.module.scss';
import * as Dialog from '@features/shared/components/dialog';

import { useIntl } from 'src/domains/i18n';
import Icons from '../../../../icons';
import { MobileClubSearchInputDialog } from './components/MobileClubSearchInputDialog';

export function MobileClubSearchInput() {
  const { formatMessage } = useIntl();

  return (
    <Dialog.Root>
      <Dialog.Trigger className={styles.triggerButton}>
        <div>{formatMessage('video-page-header.searchInput.placeholder')}</div>
        <Icons.Search className={styles.triggerIcon} />
      </Dialog.Trigger>

      <MobileClubSearchInputDialog />
    </Dialog.Root>
  );
}
