import { ContentfulImage } from '../../contentful-image';
import { getImageSizes } from 'src/features/shared/utils/get-image-sizes';
import styles from './index.module.scss';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import { IImage } from 'src/features/shared/contentful/types/IImage';
import classNames from 'classnames';

export interface IImageCollageProps {
  images?: IImage[];
}

const topMobileImages = [
  '/static/images/sportcity/form-header-images/image1.png',
  '/static/images/sportcity/form-header-images/image2.png',
  '/static/images/sportcity/form-header-images/image3.png',
  '/static/images/sportcity/form-header-images/image4.png',
];

export function ImageCollage({ images }: IImageCollageProps) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();

  const isSmall = [breakpoints.xs.name, breakpoints.sm.name].includes(
    currentBreakpoint,
  );
  if (!images || images.length !== 5) {
    console.warn('<FormHeaderImages /> must have exactly 5 images');
    return null;
  }

  const sizes = getImageSizes({ md: '200px', xl: '480px' });

  return (
    <div className={styles.imageCollage}>
      <div className={styles.imageContent}>
        {isSmall &&
          topMobileImages.slice(0, 4).map((imageSrc, index) => (
            <div
              className={classNames(
                styles.imgPositioner,
                styles.topImage,
                styles[`top-image-${index + 1}`],
              )}
              key={index}
            >
              <ContentfulImage
                key={index}
                src={imageSrc}
                alt={`image-${index + 1}`}
                className={classNames(styles.image)}
                sizes={sizes}
                fill
              />
            </div>
          ))}

        {images.map((image, index) => (
          <div
            className={classNames(
              styles.imgPositioner,
              styles[`image${index + 1}`],
            )}
            key={index}
          >
            <ContentfulImage
              key={index}
              src={image.src}
              alt={image.alt}
              className={styles.image}
              sizes={sizes}
              fill
            />
          </div>
        ))}
      </div>
    </div>
  );
}
