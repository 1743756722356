import { ComponentProps, useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import { SearchButton } from '../../../../../search-button';
import styles from './ClubSearchForm.module.scss';

interface ClubSearchFormProps extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  intialValue?: string;
  onSubmit: (searchQuery: string) => void;
}

export function ClubSearchForm({ intialValue, onSubmit }: ClubSearchFormProps) {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState(intialValue ?? '');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <input
        id="search-box"
        className={styles.input}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={formatMessage('video-page-header.searchInput.placeholder')}
        aria-label={formatMessage('video-page-header.searchInput.placeholder')}
        autoFocus
      />
      <div className={styles.buttonContainer}>
        <SearchButton type="submit" className={styles.button} />
      </div>
    </form>
  );
}
