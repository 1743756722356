import styles from './ClubSearchInput.module.scss';
import { DesktopClubSearchInput } from './desktop';
import { MobileClubSearchInput } from './mobile';

interface ClubSearchInputProps {
  isLightTheme: boolean;
}

export function ClubSearchInput({ isLightTheme }: ClubSearchInputProps) {
  return (
    <>
      <div className={styles.desktopContainer}>
        <DesktopClubSearchInput isLightTheme={isLightTheme} />
      </div>
      <div className={styles.mobileContainer}>
        <MobileClubSearchInput />
      </div>
    </>
  );
}
