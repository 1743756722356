import classNames from 'classnames';
import { Button } from 'src/features/shared/components/button';
import Icons from 'src/features/shared/components/icons';
import {
  Col,
  Container,
  Row,
  Section,
} from 'src/features/shared/components/layout';
import { H3 } from 'src/features/shared/components/typography';
import {
  IPageHeader,
  PageHeaderBackgroundStyle,
} from 'src/features/shared/contentful/types/IPageHeader';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import useLang from 'src/utils/hooks/use-lang';
import styles from './ColorPageHeader.module.scss';
import { isRichText } from 'src/features/shared/contentful/utils/isRichText';
import { PageTitle } from '../../typography/page-title/PageTitle';

export interface IColorPageHeaderProps {
  pageHeader: IPageHeader;
}

export const ColorPageHeader = ({ pageHeader }: IColorPageHeaderProps) => {
  const { lang } = useLang();
  const { renderRichText } = useRichTextRenderer(lang);
  const { breakpoints, currentBreakpoint } = useBreakpoints();
  const isMobile =
    currentBreakpoint === breakpoints.xs.name ||
    currentBreakpoint === breakpoints.sm.name;

  return (
    <Section
      className={classNames(styles.section, {
        [styles.yellow]:
          pageHeader.backgroundStyle === PageHeaderBackgroundStyle.ColorYellow,
        [styles.softPeach]:
          pageHeader.backgroundStyle ===
          PageHeaderBackgroundStyle.ColorSoftPeach,
        [styles.softSand]:
          pageHeader.backgroundStyle ===
          PageHeaderBackgroundStyle.ColorSoftSand,
      })}
    >
      <Container>
        <Row className={styles.row}>
          <Col columns="xs:12 md:6" className={styles.content}>
            <PageTitle
              title={pageHeader.title}
              className={styles.desktopTitle}
            />
            <PageTitle
              title={pageHeader.mobileTitle}
              className={styles.mobileTitle}
            />
            <H3 className={styles.subtitle}>{pageHeader.subtitle}</H3>
            <div className={styles.description}>
              {renderRichText(pageHeader.description)}
            </div>
            <div className={styles.usps}>
              {pageHeader.usps?.map((usp, index) => (
                <div key={index} className={styles.usp}>
                  <Icons.CheckmarkCircle />
                  {isRichText(usp.description) ? (
                    renderRichText(usp.description)
                  ) : (
                    <span>{usp.description}</span>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.buttons}>
              {pageHeader.primaryButton && (
                <Button
                  variant={ButtonVariant.Secondary}
                  fullWidth={isMobile}
                  className={styles.primaryButton}
                  href={pageHeader.primaryButton.href}
                >
                  {pageHeader.primaryButton.label}
                </Button>
              )}

              {pageHeader.secondaryButton && (
                <Button
                  variant={ButtonVariant.Inverted}
                  fullWidth={isMobile}
                  className={styles.secondaryButton}
                  href={pageHeader.secondaryButton.href}
                >
                  {pageHeader.secondaryButton.label}
                </Button>
              )}
            </div>
          </Col>
          {pageHeader.image && (
            <Col columns="xs:12 md:6" className={styles.imageWrapper}>
              <img
                src={pageHeader.image.src}
                alt={pageHeader.image.alt}
                className={styles.image}
              />
            </Col>
          )}
        </Row>
      </Container>
    </Section>
  );
};
