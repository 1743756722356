import styles from './MobileClubSearchInputDialog.module.scss';
import * as Dialog from '@features/shared/components/dialog';
import React from 'react';
import { useIntl } from 'src/domains/i18n';
import { ClubSearchForm } from './ClubSearchForm';
import { Link } from 'src/features/shared/components/link';
import { UseMyLocationButton } from 'src/features/shared/components/use-my-location-button';
import { SEARCH_SUGGESTIONS } from '../../config/search-suggestions';
import { useRouter } from 'next/navigation';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';

export function MobileClubSearchInputDialog() {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const handleSubmit = (searchQuery: string) => {
    pushGA4Event(GA4_EVENTS.search, {
      search_term: searchQuery,
      seearch_type: 'default',
    });
    router.push(`/clubs?searchQuery=${searchQuery}`);
  };

  return (
    <Dialog.Content
      className={styles.dialogContent}
      title={formatMessage('club-search-input.mobile.dialog.title')}
    >
      <div className={styles.formContainer}>
        <ClubSearchForm onSubmit={handleSubmit} />
      </div>
      <ul className={styles.ul}>
        <li>
          <UseMyLocationButton
            href={'/clubs?searchNearby=true&searchQuery=In%20de%20buurt'}
            className={styles.locationButton}
          />
        </li>
        {SEARCH_SUGGESTIONS.map(({ label, query }) => (
          <li key={query}>
            <Link
              href={`/clubs?searchQuery=${query}`}
              className={styles.suggestionLink}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </Dialog.Content>
  );
}
