'use client';

import { ConfettiEffect } from '@features/shared/components/confetti';
import { PageHeader } from '@features/shared/components/page-header/PageHeader';
import ContentBlocksSectionView from '@views/sections/content-blocks';
import { CONTENTFUL_TAGS } from 'src/features/shared/utils/constants';
import { ContentIndex } from 'src/features/shared/components/content-index';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import { GA4_EVENTS, pushGA4Event } from '../../utils/ga4';
import { getPageType } from '../../utils/ga4/get-page-type';
import { useEffect } from 'react';

interface IContentPageViewProps {
  page: IContentPage;
  pageSlug: string;
}

export const ContentPageView = ({ page, pageSlug }: IContentPageViewProps) => {
  // Only pages with tag SEO should display the content index component
  const showContentIndex = page.contentfulTags.includes(CONTENTFUL_TAGS.seo);

  useEffect(() => {
    if (page) {
      pushGA4Event(GA4_EVENTS.pageData, {
        page_type: page.pageType || getPageType(pageSlug),
      });
    }
  }, [page, pageSlug]);

  return (
    <>
      {page.showConfetti && <ConfettiEffect />}

      <PageHeader pageHeader={page.pageHeader} />
      {showContentIndex && (
        <ContentIndex
          title={page.contentTableTitle}
          contentBlocks={page.contentBlocks}
        />
      )}

      {page.contentBlocks && (
        <ContentBlocksSectionView
          page={page}
          contentBlocks={page.contentBlocks}
        />
      )}
    </>
  );
};
