import styles from './PageTitle.module.scss';
import classNames from 'classnames';
import { ElementType } from 'react';
import { parsePageTitle } from './utils/parse-page-title';
import {
  isHighlightSegment,
  isNewLineSegment,
  isTextSegment,
} from './utils/validate-page-title-segments';
import { Highlight } from './components/Highlight';
import { TitleSegment } from './types';

export interface IPageTitleProps {
  isDark?: boolean;
  title: string;
  className?: string;
  element?: ElementType;
}

export const PageTitle = ({
  isDark = false,
  title,
  className,
  element: Element = 'h1',
}: IPageTitleProps) => {
  const segments = parsePageTitle(title);

  const renderSegment = (segment: TitleSegment) => {
    if (isTextSegment(segment)) {
      return segment.value;
    }
    if (isHighlightSegment(segment)) {
      return <Highlight>{segment.value}</Highlight>;
    }
    if (isNewLineSegment(segment)) {
      return <br />;
    }
  };

  return (
    <Element
      className={classNames(
        styles.pageTitle,
        { [styles.dark]: isDark },
        className,
      )}
      style={{
        '--title-color': isDark ? '#fff' : '#000',
      }}
    >
      {segments.map(renderSegment)}
    </Element>
  );
};
