import styles from './Highlight.module.scss';
import { ComponentProps } from 'react';
import HightlightSvg from '../../../../../assets/icons/title-highlight-bg.svg';

export function Highlight({ children, ...props }: ComponentProps<'span'>) {
  return (
    <span className={styles.highlightedText} {...props}>
      <HightlightSvg />
      {children}
    </span>
  );
}
