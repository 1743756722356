import classNames from 'classnames';
import styles from './VideoPageHeader.module.scss';
import { Button } from 'src/features/shared/components/button';
import { H4 } from 'src/features/shared/components/typography';
import { ClubSearchInput } from './components/club-search-input';
import { StickerButton } from './components/sticker-button/StickerButton';
import { IVideoPageHeader } from 'src/features/shared/contentful/types/IVideoPageHeader';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { HeaderVideo } from './components/header-video/HeaderVideo';
import { PageTitle } from '../page-title/PageTitle';

interface IVideoPageHeaderProps {
  data: IVideoPageHeader;
}

export function VideoPageHeader({ data }: IVideoPageHeaderProps) {
  const isLightTheme = data.theme === 'Light';
  const hasTwoButtons = data.primaryButton && data.secondaryButton;

  const onButtonClick = (ctaType: string, ctaName: string, url: string) => {
    pushGA4Event(GA4_EVENTS.callToAction, {
      call_to_action_type: ctaType,
      interaction_type: 'click',
      call_to_action_name: ctaName,
      link_url: url,
    });
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.lightTheme]: isLightTheme,
      })}
    >
      <HeaderVideo
        desktopSrc={data.backgroundVideo.url}
        mobileSrc={data.mobileBackgroundVideo.url}
        className={styles.video}
      />

      <div
        className={classNames(styles.contentWrapper, {
          [styles.overlapping]: hasTwoButtons,
        })}
      >
        <div className={styles.titleWrapper}>
          <PageTitle title={data.title} className={styles.desktopTitle} />
          <PageTitle title={data.mobileTitle} className={styles.mobileTitle} />

          <H4 className={styles.subtitle}>{data.subtitle}</H4>

          <StickerButton
            visibleOn="mobile"
            image={data.stickerButtonImage}
            link={data.stickerButtonLink}
            onClick={() =>
              onButtonClick(
                'image link',
                'sticker button',
                data.stickerButtonLink ? data.stickerButtonLink.href : '',
              )
            }
          />
        </div>

        <div className={styles.ctaWrapper}>
          <div className={styles.inputWrapper}>
            {data.hasClubSearch && (
              <ClubSearchInput isLightTheme={isLightTheme} />
            )}
            <StickerButton
              visibleOn="desktop"
              image={data.stickerButtonImage}
              link={data.stickerButtonLink}
              onClick={() =>
                onButtonClick(
                  'image link',
                  'sticker button',
                  data.stickerButtonLink ? data.stickerButtonLink.href : '',
                )
              }
            />
          </div>
          {data.primaryButton || data.secondaryButton ? (
            <div className={styles.buttonsWrapper}>
              {data.primaryButton && (
                <Button
                  className={classNames(styles.button, styles.primaryButton)}
                  variant={ButtonVariant.Primary}
                  onClick={() =>
                    onButtonClick(
                      'button',
                      'video header primary button',
                      data.primaryButton ? data.primaryButton.href : '',
                    )
                  }
                  href={data.primaryButton.href}
                >
                  {data.primaryButton.label}
                </Button>
              )}
              {data.secondaryButton && (
                <Button
                  variant={
                    isLightTheme
                      ? ButtonVariant.Inverted
                      : ButtonVariant.SecondaryInverted
                  }
                  onClick={() =>
                    onButtonClick(
                      'button',
                      'video header secondary button',
                      data.secondaryButton ? data.secondaryButton.href : '',
                    )
                  }
                  href={data.secondaryButton.href}
                  className={classNames(styles.button, styles.secondaryButton, {
                    [styles.darkTheme]: isLightTheme,
                  })}
                >
                  {data.secondaryButton.label}
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
