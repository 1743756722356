import { useEffect, useRef } from 'react';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';

interface IVideoProps {
  desktopSrc: string;
  mobileSrc: string;
  className?: string;
}

export function HeaderVideo({ desktopSrc, mobileSrc, className }: IVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;
  const videoSrc = isMobile ? mobileSrc : desktopSrc;

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const isPlaying =
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA;

      if (isPlaying) {
        return;
      }

      if ('connection' in navigator && navigator?.connection) {
        const connection = navigator.connection as { effectiveType: string };
        if (!['slow-2g', '2g', '3g'].includes(connection.effectiveType)) {
          videoRef.current?.play();
        }
      } else {
        videoRef.current?.play();
      }
    }
  }, []);

  return (
    <video
      ref={videoRef}
      muted
      loop
      onCanPlayThrough={() => {
        videoRef.current?.play();
      }}
      playsInline
      data-testid="bg-video"
      src={videoSrc}
      className={className}
    />
  );
}
