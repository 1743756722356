export const SEARCH_SUGGESTIONS = [
  {
    label: 'Amsterdam',
    query: 'Amsterdam',
  },
  {
    label: 'Den Haag',
    query: 'Den%20Haag',
  },
  {
    label: 'Leiden',
    query: 'Leiden',
  },
  {
    label: 'Rotterdam',
    query: 'Rotterdam',
  },
  {
    label: 'Utrecht',
    query: 'Utrecht',
  },
];
