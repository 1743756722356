export class NewLineSegment {
  type = 'new-line';
}

export class TextSegment {
  type = 'text';
  constructor(public value: string) {}
}

export class HighlightSegment {
  type = 'highlight';
  constructor(public value: string) {}
}

export type TitleSegment = TextSegment | HighlightSegment | NewLineSegment;
