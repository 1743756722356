import classNames from 'classnames';
import styles from './ImagePageHeader.module.scss';
import { Button } from '@features/shared/components/button';
import { Layout } from '@features/shared/components/layout';
import Icons from 'features/shared/components/icons';
import { Link } from 'features/shared/components/link';
import { ImageCollage } from 'src/features/shared/components/page-header/image-collage';
import { RichText } from 'src/features/shared/components/rich-text';
import { FontColor, H4 } from 'src/features/shared/components/typography';
import { UspItem } from 'src/features/shared/components/usp-item';
import { IPageHeader } from 'src/features/shared/contentful/types/IPageHeader';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import useLang from 'utils/hooks/use-lang';
import { PageTitle } from '../../typography/page-title/PageTitle';
import { IPrevLink } from '../types/IPrevLink';
import { ContentfulImage } from '../../contentful-image';

export interface IPageHeaderProps {
  pageHeader: IPageHeader;
  prevLink?: IPrevLink;
  children?: React.ReactNode;
}

export const ImagePageHeader = ({
  pageHeader,
  prevLink,
  children,
}: IPageHeaderProps) => {
  const {
    hasFontColorLight,
    title,
    mobileTitle,
    subtitle,
    description,
    primaryButton: cta,
    image,
    mobileImage,
    collageImages,
    contentAlignment = 'Left',
    usps,
    backgroundStyle: imageStyle,
  } = pageHeader;
  const { lang } = useLang();
  const { renderRichText } = useRichTextRenderer(lang);
  const { breakpoints, currentBreakpoint } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;
  const descriptionToRender = description && renderRichText(description);
  const isCollageStyle = imageStyle === 'Collage';
  const isLightTheme =
    !isCollageStyle &&
    ((!image && !mobileImage) || hasFontColorLight !== false);
  const isAlignedCenter = contentAlignment === 'Center' && !isCollageStyle;

  const columnProps = isCollageStyle
    ? { columns: 'sm:5', offset: 'sm:1' }
    : {
        columns: 'xs:12',
      };

  return (
    <div
      className={classNames(styles.container, {
        [styles.alternative]: !image,
        [styles['is-neutral-06']]: !isLightTheme,
        [styles.isCollage]: isCollageStyle,
      })}
    >
      {image && !isCollageStyle ? (
        <div
          className={
            title &&
            classNames(styles['image-container'], {
              [styles.transparent]: !isLightTheme,
              [styles.centralized]: isAlignedCenter,
            })
          }
        >
          <ContentfulImage
            className={styles.image}
            src={isMobile && mobileImage ? mobileImage.src : image.src}
            alt={image.alt}
            fill
          />
        </div>
      ) : null}
      <Layout.Container>
        <Layout.Row className={styles.row}>
          <Layout.Col {...columnProps}>
            <div
              className={classNames(styles.body, {
                [styles.centralized]: isAlignedCenter,
                [styles.bodyPaddingTop]: !isCollageStyle,
              })}
            >
              {subtitle && (
                <div className={styles.subtitle}>
                  <H4
                    color={
                      isLightTheme ? FontColor.Neutral06 : FontColor.Neutral01
                    }
                  >
                    {subtitle}
                  </H4>
                </div>
              )}

              <PageTitle
                title={title}
                isLight={isLightTheme}
                className={classNames(styles.title, styles.desktopTitle)}
              />
              <PageTitle
                title={mobileTitle}
                isLight={isLightTheme}
                className={classNames(styles.title, styles.mobileTitle)}
              />

              {descriptionToRender && (
                <RichText
                  isLight={isLightTheme}
                  className={styles.largeSizeOnTablet}
                >
                  <>{descriptionToRender}</>
                </RichText>
              )}
              {usps && (
                <ul className={styles.usps}>
                  {usps.map((usp, index) => (
                    <UspItem
                      key={index}
                      usp={usp}
                      index={index}
                      isColorNeutral06={isLightTheme}
                    />
                  ))}
                </ul>
              )}
              {cta && (
                <div className={styles.cta}>
                  <Button href={cta.href} openInNewTab={cta.openInNewTab}>
                    {cta.label}
                  </Button>
                </div>
              )}
              <Layout.Row>
                <Layout.Col columns={isAlignedCenter ? 'sm:12' : 'sm:8'}>
                  {children}
                </Layout.Col>
              </Layout.Row>
              {prevLink && (
                <div className={styles.link}>
                  <Link
                    href={prevLink?.href}
                    icon={Icons.ArrowLeft}
                    className={styles.prevLink}
                  >
                    {prevLink?.label}
                  </Link>
                </div>
              )}
            </div>
          </Layout.Col>
          {isCollageStyle && collageImages ? (
            <Layout.Col
              className={styles.collageCol}
              columns="sm:6 md:5 lg:4"
              offset="md:1"
            >
              <ImageCollage images={collageImages} />
            </Layout.Col>
          ) : null}
        </Layout.Row>
      </Layout.Container>
    </div>
  );
};
