'use client';
import styles from './Confetti.module.scss';
import * as localstorage from '@utils/local-storage';
import { useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

export const ConfettiEffect = () => {
  const refAnimationInstance = useRef<confetti.CreateTypes | null>(null);

  // set max of the page visits to 2 so the confetti only shows twice
  const maxViews = 2;
  const showConfetti = Number(localstorage.getItem('pageVisit')) <= maxViews;

  const getInstance = useCallback((instance: confetti.CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback(
    (particleRatio: number, opts: confetti.Options) => {
      refAnimationInstance.current &&
        refAnimationInstance.current({
          ...opts,
          origin: { y: 0.5, x: 0.5 },
          particleCount: Math.floor(250 * particleRatio),
          shapes: ['circle', 'square'],
          colors: ['#F4B52C', '#F4B52C', '#E5E5E5', '#F7D55D', '#FBECB7'],
          scalar: 1.8,
          decay: 0.8,
          ticks: 600,
          spread: window.innerWidth,
          startVelocity: 120,
        });
    },
    [],
  );

  useEffect(() => {
    if (showConfetti) {
      makeShot(0.5, {});
    }
  }, [showConfetti, makeShot]);

  useEffect(() => {
    const pageVisit = localstorage.getItem('pageVisit');

    if (pageVisit) {
      // if page is already visited add 1 to current visit count
      const allVisits = Number(localstorage?.getItem('pageVisit')) + 1;
      localStorage.setItem('pageVisit', String(allVisits));
    } else {
      // if page is not visited yet, set page visit to 1
      localstorage.setItem('pageVisit', 1);
    }
  }, []);

  return (
    <>
      {showConfetti && (
        <ReactCanvasConfetti
          refConfetti={getInstance}
          fire
          className={styles.canvas}
        />
      )}
    </>
  );
};
