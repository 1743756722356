import {
  TextSegment,
  HighlightSegment,
  NewLineSegment,
  TitleSegment,
} from '../types';

export function isTextSegment(segment: TitleSegment): segment is TextSegment {
  return segment.type === 'text';
}

export function isHighlightSegment(
  segment: TitleSegment,
): segment is HighlightSegment {
  return segment.type === 'highlight';
}

export function isNewLineSegment(
  segment: TitleSegment,
): segment is NewLineSegment {
  return segment.type === 'new-line';
}
