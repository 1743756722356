import {
  HighlightSegment,
  NewLineSegment,
  TextSegment,
  TitleSegment,
} from '../types';

function parseHighlightedText(text: string): TitleSegment[] {
  const segments: TitleSegment[] = [];
  const parts = text.split('\\n');

  parts.forEach((part, index) => {
    if (part) {
      segments.push(new HighlightSegment(part));
    }
    if (index < parts.length - 1) {
      segments.push(new NewLineSegment());
    }
  });

  return segments;
}

function parseText(text: string): TitleSegment[] {
  const segments: TitleSegment[] = [];
  const parts = text.split('\\n');

  parts.forEach((part, index) => {
    if (part) {
      segments.push(new TextSegment(part));
    }
    if (index < parts.length - 1) {
      segments.push(new NewLineSegment());
    }
  });

  return segments;
}

export function parsePageTitle(title: string): TitleSegment[] {
  const segments: TitleSegment[] = [];
  let currentIndex = 0;

  while (currentIndex < title.length) {
    // Check for highlighted text
    const highlightMatch = title.slice(currentIndex).match(/^\*(.*?)\*/);
    if (highlightMatch) {
      const highlightedSegments = parseHighlightedText(highlightMatch[1]);
      segments.push(...highlightedSegments);
      currentIndex += highlightMatch[0].length;
      continue;
    }

    // Check for plain text until next highlight or end
    const nextHighlight = title.indexOf('*', currentIndex);
    const textEnd = nextHighlight === -1 ? title.length : nextHighlight;
    const text = title.slice(currentIndex, textEnd);

    if (text) {
      const textSegments = parseText(text);
      segments.push(...textSegments);
    }

    currentIndex = textEnd;
  }

  return segments;
}
