import styles from './DesktopClubSearchInput.module.scss';
import classNames from 'classnames';
import { UseMyLocationButton } from 'features/shared/components/use-my-location-button';
import { useRouter } from 'next/navigation';
import { FormEvent, Fragment, useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import { Link } from 'src/features/shared/components/link';
import { SearchButton } from 'src/features/shared/components/search-button';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { SEARCH_SUGGESTIONS } from '../config/search-suggestions';

interface ClubSearchInputProps {
  isLightTheme: boolean;
}

export function DesktopClubSearchInput({ isLightTheme }: ClubSearchInputProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const { formatMessage } = useIntl();
  const router = useRouter();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    pushGA4Event(GA4_EVENTS.search, {
      search_term: searchQuery,
      seearch_type: 'default',
    });

    router.push(`/clubs?searchQuery=${searchQuery}`);
  };

  return (
    <div
      className={classNames(styles.body, { [styles.lightTheme]: isLightTheme })}
    >
      <form className={styles.inputContainer} onSubmit={onSubmit}>
        <input
          id="search-box"
          className={styles.input}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={formatMessage(
            'video-page-header.searchInput.placeholder',
          )}
          aria-label={formatMessage(
            'video-page-header.searchInput.placeholder',
          )}
        />
        <div className={styles.searchButtonContainer}>
          <SearchButton type="submit" className={styles.searchButton} />
        </div>
      </form>

      <div className={styles.links}>
        <div>
          <span>{formatMessage('video-page-header.cities-links.label')}</span>
          {SEARCH_SUGGESTIONS.map(({ label, query }, index) => (
            <Fragment key={label}>
              <Link key={label} href={`/clubs?searchQuery=${query}`}>
                {label}
              </Link>
              {index < SEARCH_SUGGESTIONS.length - 1 && <span>, </span>}
            </Fragment>
          ))}
        </div>

        <UseMyLocationButton
          href={'/clubs?searchNearby=true&searchQuery=In%20de%20buurt'}
          className={styles.locationButton}
          isLight
        />
      </div>
    </div>
  );
}
